<template>
    <v-app>
        <v-main class="d-flex justify-center align-center">
            <v-card
                max-width="480"
                sm="320"
                xs="320"
                class="mx-auto"
                :loading="loading"
            >
                <v-card-title class="d-flex justify-center pb-0">
                    <div class="d-flex justify-center">
                        <v-img
                            v-if="company.logo"
                            :src="company.logo"
                            class="pa-0 mt-3 mb-5"
                            max-height="120"
                            width="50%"
                            xs="80"
                            sm="80"
                            contain
                        />
                    </div>
                    <div>
                        <h4 v-if="!operator">OPERATOR AUTHENTICATION</h4>
                        <h4 v-else>SEARCH BOM BY CODE</h4>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="submitForm">
                        <v-row no-gutters class="ma-3">
                            <v-col cols="12">
                                <v-text-field
                                    v-if="!operator"
                                    v-model="operatorCode"
                                    @click:append="show = !show"
                                    prepend-icon="mdi-account-hard-hat"
                                    :append-icon="
                                        show ? 'mdi-eye-off' : 'mdi-eye'
                                    "
                                    :type="show ? 'text' : 'password'"
                                    label="Code *"
                                    hide-details
                                    :rules="[rules.required]"
                                    required
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-if="operator" class="mt-4 mx-3">
                            <v-col cols="12">
                                <v-text-field
                                    v-model="bomCode"
                                    prepend-icon="mdi-database-search"
                                    label="BOM Code *"
                                    hide-details
                                    :rules="[rules.required]"
                                    required
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4 mx-3" v-if="!operator">
                            <v-col cols="12">
                                <div class="input-container">
                                    <v-checkbox
                                        v-model="checkToken"
                                        @click="onClick"
                                        :loading="loading"
                                    ></v-checkbox>
                                    <span class="checkbox-text ml-2"
                                        >I'm not a robot</span
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters class="mb-3 mr-2">
                        <v-spacer />
                        <v-btn
                            v-if="!operator"
                            color="primary"
                            rounded
                            @click="searchOperator"
                            :loading="loading"
                            :disabled="!valid || !reCaptchaVerify"
                        >
                            NEXT
                        </v-btn>
                        <v-btn
                            v-if="operator"
                            color="primary"
                            rounded
                            @click="searchBOM"
                            :loading="loading"
                            :disabled="!valid"
                        >
                            SEARCH
                        </v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-main>
        <!--PROCESS BOM-->
        <v-dialog v-model="openBOM" :retain-focus="false" persistent fullscreen>
            <ProcessBOM
                v-if="openBOM"
                :bom="bom"
                process="Bill Of Material"
                :readBOMOnly="true"
                @close="closeBOM"
            />
        </v-dialog>
    </v-app>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'BOMSearch',
    props: {
        companyId: {
            type: String,
            required: true,
        },
    },
    components: {
        ProcessBOM: () => import('@/components/WorkOrders/ProcessBOM.vue'),
    },
    data: () => ({
        valid: false,
        operatorCode: null,
        loading: false,
        operator: null,
        bom: {},
        openBOM: false,
        bomCode: null,
        checkToken: false,
        reCaptchaVerify: false,
        company: {},
        show: false,
        rules: {
            required: v => !!v || 'The value is required.',
        },
    }),
    async mounted() {
        try {
            this.loading = true
            this.company = await API.getLiteCompany(this.companyId)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onClick(e) {
            try {
                e.preventDefault()
                if (this.checkToken) {
                    window.grecaptcha.enterprise.ready(async () => {
                        try {
                            this.loading = true
                            const token = await window.grecaptcha.enterprise.execute(
                                process.env.VUE_APP_RECAPTCHA_SITE_KEY,
                                { action: process.env.VUE_APP_RECAPTCHA_ACTION }
                            )
                            const riskAnalysis = await API.validateReCaptcha(
                                token
                            )
                            if (
                                riskAnalysis &&
                                riskAnalysis.reasons.length > 0
                            ) {
                                this.reCaptchaVerify = false
                            } else if (
                                riskAnalysis &&
                                riskAnalysis.score >= 0.8
                            ) {
                                this.reCaptchaVerify = true
                            } else {
                                this.reCaptchaVerify = false
                                this.setErrorItems({
                                    source: this.$options.name,
                                    message: 'reCaptcha ERROR',
                                })
                            }
                        } catch (error) {
                            this.setErrorItems({
                                source: this.$options.name,
                                message: error.message,
                            })
                        } finally {
                            this.loading = false
                        }
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeBOM() {
            this.openBOM = false
            this.bom = {}
        },
        async searchBOM() {
            try {
                this.loading = true
                this.bom = await API.findBOMByCode(this.bomCode)
                this.openBOM = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async searchOperator() {
            try {
                this.loading = true
                this.operator = await API.searchOperatorByCode(
                    this.companyId,
                    this.operatorCode
                )
                this.reCaptchaVerify = false
                this.checkToken = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        submitForm() {
            if (this.valid) {
                this.operator ? this.searchBOM() : this.searchOperator()
            }
        },
    },
}
</script>

<style scoped>
.input-container {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 54px;
}
.checkbox-text {
    font-size: 24px !important;
    color: #000000;
}
</style>
